// in src/dataProvider
import {
    CREATE,
    DELETE,
    DELETE_MANY,
    fetchUtils,
    GET_LIST,
    GET_MANY,
    GET_MANY_REFERENCE,
    GET_ONE,
    UPDATE,
} from 'react-admin';
import {stringify} from 'query-string';

const API_URL = process.env.REACT_APP_API_URL;

/**
 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} { url, options } The HTTP request parameters
 */
const convertDataProviderRequestToHTTP = (type, resource, params) => {
    switch (type) {
        case GET_LIST: {
            const {page, perPage} = params.pagination;
            const {field, order} = params.sort;

            var sortField = field;

            if (order === 'DESC')
                sortField = '-' + field;

            const query = {
                sort: sortField,
                page: page,
                perPage: perPage,
            };

            var queryUrl = `${API_URL}/${resource}?${stringify(query)}`;

            Object.keys(params.filter).map(function(key) {
                queryUrl +=  "&filter[" + key + "]=" + params.filter[key];
            });

            return {url: queryUrl};
        }
        case GET_ONE:
            return {url: `${API_URL}/${resource}/${params.id}`};
        case GET_MANY: {
            var query = "";

            if (params.ids) {
                query = "?filter[id]=" + params.ids.join(',');
            }

            return {url: `${API_URL}/${resource}${query}`};
        }
        case GET_MANY_REFERENCE: {
            // const {page, perPage} = params.pagination;
            // const {field, order} = params.sort;

            // const query = {
            //     sort: JSON.stringify([field, order]),
            //     range: JSON.stringify([(page - 1) * perPage, (page * perPage) - 1]),
            //     filter: JSON.stringify({...params.filter, [params.target]: params.id}),
            // };
            //TODO: Fitlering for request

            const query = "filter[" + params.target + "]=" + params.id;

            // return {url: `${API_URL}/${resource}?${stringify(query)}`};
            return {url: `${API_URL}/${resource}?${query}`};
        }
        case UPDATE:
            return {
                url: `${API_URL}/${resource}/${params.id}`,
                options: {method: 'POST', body: parseFormData(params.data, resource, params)},
            };
        case CREATE:
            return {
                url: `${API_URL}/${resource}`,
                options: {method: 'POST', body: parseFormData(params.data, resource, params)},
                // options: {method: 'POST', body: JSON.stringify(params.data)},
            };
        case DELETE:
            return {
                url: `${API_URL}/${resource}/${params.id}`,
                options: {method: 'DELETE'},
            };
        case DELETE_MANY:
            return {
                url: `${API_URL}/${resource}/${params.ids}`,
                options: {method: 'DELETE'},
            };
        default:
            throw new Error(`Unsupported fetch action type ${type}`);
    }
};

/**
 * @param {Object} response HTTP response from fetch()
 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} Data Provider response
 */
const convertHTTPResponseToDataProvider = (response, type, resource, params) => {
    const {json} = response;
    // const {headers, json} = response;

    var result = {};

    switch (type) {
        case DELETE_MANY:
        case DELETE:
            result.data = {id: params.id};
            break;
        case GET_LIST:
        case GET_MANY:
        case GET_MANY_REFERENCE:
            result.data = json.data;

            result.total = parseInt(json.total, 10);
            break;
        case CREATE:
            result.data = {...params.data, id: json.id};
            break;
        default:
            result.data = json.data;
    }

    return result;
};

const parseFormData = (formData, resource, params) => {

    if (resource === 'assets') {
        if (params.data.file) {
            const uploadFormdata = new FormData();
            // uploadFormdata.append('title', params.data.title);
            // uploadFormdata.append('description', params.data.description);
            // uploadFormdata.append('asset_mime_id', params.data.asset_mime_id);
            // uploadFormdata.append('asset_type_id', params.data.asset_type_id);
            uploadFormdata.append('file', params.data.file.rawFile);

            for (const [key, value] of Object.entries(params.data)) {

                if (key !== 'file') {
                    uploadFormdata.append(key, value);
                }
            }

            return uploadFormdata;
        }
    }

    return JSON.stringify(formData);
};

/**
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the request type
 * @returns {Promise} the Promise for response
 */
export default (type, resource, params) => {
    const {fetchJson} = fetchUtils;
    var {url, options} = convertDataProviderRequestToHTTP(type, resource, params);

    if (typeof options === 'undefined')
        options = {};

    if (!options.headers) {
        options.headers = new Headers({Accept: 'application/json'});
    }

    options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));

    return fetchJson(url, options)
        .then(response => convertHTTPResponseToDataProvider(response, type, resource, params));
};