import React from 'react';
import {
    Create,
    Datagrid,
    DeleteButton,
    DisabledInput,
    Edit,
    EditButton,
    List,
    SimpleForm,
    TextField,
    TextInput
} from 'react-admin';

export const AttributeList = props => (
    <List {...props} exporter={false}>
        <Datagrid rowClick="edit">
            <TextField source="id"/>
            <TextField source="title"/>
            <TextField source="label.de" label="Label DE" sortable={false}/>
            <TextField source="label.en" label="Label EN" sortable={false}/>
            <EditButton/>
            <DeleteButton/>
        </Datagrid>
    </List>
);

export const AttributeEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <DisabledInput source="id"/>
            <TextInput source="title"/>
            <TextInput source="label.de" label="Label DE"/>
            <TextInput source="label.en" label="Label EN"/>
            <TextInput source="label.fr" label="Label (FR)"/>
            <TextInput source="label.nl" label="Label (NL)"/>
        </SimpleForm>
    </Edit>
);

export const AttributeCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="title"/>
            <TextInput source="label.de" label="Label DE"/>
            <TextInput source="label.en" label="Label EN"/>
            <TextInput source="label.fr" label="Label (FR)"/>
            <TextInput source="label.nl" label="Label (NL)"/>
        </SimpleForm>
    </Create>
);

export const SimpleAttributeEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <DisabledInput source="id"/>
            <TextInput source="title"/>
        </SimpleForm>
    </Edit>
);

export const SimpleAttributeCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="title"/>
        </SimpleForm>
    </Create>
);

export const SimpleAttributeList = props => (
    <List {...props} exporter={false}>
        <Datagrid rowClick="edit">
            <TextField source="id"/>
            <TextField source="title"/>
            <EditButton/>
            <DeleteButton/>
        </Datagrid>
    </List>
);
