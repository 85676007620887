import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = {
    root: {display: 'inline-block', marginTop: '1em', zIndex: 2},
    content: {padding: 0, '&:last-child': {padding: 0}},
    img: {
        width: 'initial',
        minWidth: 'initial',
        maxWidth: '42em',
        maxHeight: '15em',
    },
    video: {
        width: 'initial',
        minWidth: 'initial',
        maxWidth: '42em',
        maxHeight: '15em',
    }
};

function getExtension(filename) {
    var parts = filename.split('.');
    return parts[parts.length - 1];
}

function isImage(filename) {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
        case 'jpg':
        case 'jpeg':
        case 'gif':
        case 'bmp':
        case 'png':
            return true;
        default:
            return false;
    }
}

function isVideo(filename) {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
        case 'm4v':
        case 'avi':
        case 'mpg':
        case 'mp4':
            return true;
        default:
            return false;
    }
}

function isAudio(filename) {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
        case 'mp3':
        case 'mpga':
            return true;
        default:
            return false;
    }
}

const Poster = withStyles(styles)(({classes, record}) => (
    <Card className={classes.root}>
        <CardContent className={classes.content}>
            {(() => {
                if (record.path) {
                    if (isImage(record.path))
                        return <img src={record.path} alt="" className={classes.img}/>;
                    else if (isVideo(record.path))
                        return <video controls className={classes.video}>
                            <source src={record.path} type="video/mp4"/>
                        </video>;
                    else if (isAudio(record.path))
                        return <audio src={record.path} controls>No Audio Support</audio>;
                }
            })()}
        </CardContent>
    </Card>
));

export default Poster;