import React from 'react';
import {Create, Datagrid, DeleteButton, Edit, EditButton, List, SimpleForm, TextField, TextInput} from 'react-admin';

export const PricingList = props => (
    <List {...props} exporter={false}>
        <Datagrid rowClick="edit">
            <TextField source="id"/>
            <TextField source="title"/>
            <TextField source="label.de" label="Label DE" sortable={false}/>
            <TextField source="label.en" label="Label EN" sortable={false}/>
            <EditButton/>
            <DeleteButton/>
        </Datagrid>
    </List>
);

export const PricingEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="id"/>
            <TextInput source="title"/>
            <TextInput source="label.de" label="Label DE"/>
            <TextInput source="label.en" label="Label EN"/>
            <TextInput source="label.fr" label="Label FR"/>
            <TextInput source="label.nl" label="Label NL"/>
        </SimpleForm>
    </Edit>
);

export const PricingCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="title"/>
            <TextInput source="label.de" label="Label DE"/>
            <TextInput source="label.en" label="Label EN"/>
            <TextInput source="label.fr" label="Label FR"/>
            <TextInput source="label.nl" label="Label NL"/>
        </SimpleForm>
    </Create>
);