import React from 'react';
import {
    AutocompleteInput,
    BooleanField,
    BooleanInput,
    CloneButton,
    Create,
    Datagrid,
    DeleteButton,
    Edit,
    EditButton,
    Error,
    FormTab,
    Labeled,
    List,
    Loading,
    Query,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    TabbedForm,
    TextField,
    TextInput,
    UrlField
} from 'react-admin';
import {ColorInput} from 'react-admin-color-input';
import {Field} from 'redux-form';

import {Editor} from "@tinymce/tinymce-react";

export const ThemeLocalizationList = props => (
    <List {...props} exporter={false}>
        <Datagrid rowClick="edit">
            <TextField source="id"/>
            <ReferenceField source="theme_id" reference="themes">
                <TextField source="title"/>
            </ReferenceField>
            <TextField source="title"/>
            <TextField source="language"/>
            <UrlField source="previewUrl" target="_blank" sortable={false}/>
            <BooleanField source="published"/>
            <EditButton/>
            <CloneButton/>
            <DeleteButton/>
        </Datagrid>
    </List>
);

const languageChoices = [
    {id: 'de', name: 'Deutsch'},
    {id: 'en', name: 'Englisch'},
    {id: 'fr', name: 'Französisch'},
    {id: 'nl', name: 'Niederländisch'},
    {id: 'es', name: 'Spanisch'},
    {id: 'it', name: 'Italienisch'},
];

export const ThemeLocalizationEdit = props => (
    <Edit {...props}>
        <TabbedForm>
            <FormTab label="General">
                <ReferenceInput source="theme_id" reference="themes"
                                filterToQuery={searchText => ({title: searchText})}>
                    <AutocompleteInput optionText="title" fullWidth={true}/>
                </ReferenceInput>
                <TextInput source="title"/>
                <TextInput source="description"/>
                <SelectInput source="language" choices={languageChoices}/>
                <BooleanInput source="published"/>
            </FormTab>
            <FormTab label="Preview">
                <TextInput source="previewUrl"/>

                <ReferenceInput source="thumbnail_asset_id" reference="assets" allowEmpty={true}
                                filterToQuery={searchText => ({original_name: searchText})}>
                    <AutocompleteInput optionText="original_name" fullWidth={true}/>
                </ReferenceInput>

                <ReferenceInput source="email_preview_asset_id" reference="assets" allowEmpty={true}
                                filterToQuery={searchText => ({original_name: searchText})}>
                    <AutocompleteInput optionText="original_name" fullWidth={true}/>
                </ReferenceInput>
            </FormTab>
            <FormTab label="Data">
                <DataFields/>
            </FormTab>
        </TabbedForm>
    </Edit>
);

const DataFields = ({record}) => (
    record.theme ? (
        <Query type="GET_ONE" resource="templates" payload={{id: record.theme.template_id}}>
            {({data, total, loading, error}) => {
                if (loading) {
                    return <Loading/>
                }

                if (error) {
                    return <Error/>;
                }

                // var entries = data.data.map(function (dataItem, index) {
                //     return <AssetReferenceInput key={dataItem.name} name={dataItem.name} record={dataItem}
                //                                 index={index}/>;
                // });
                //

                var fields = data.data;

                if (Array.isArray(record.data)) {
                    return (
                        <span>
                        {/*{fields.map(field => <li key={field.name}>{field.name + ' ' + field.value}</li>)}*/}
                            {/*{fields.map((field, index) => {*/}
                            {fields.map((field) => {

                                    // if (!record.data)
                                    //     record.data = [];
                                    // var fieldValue = record.data[index].value.toString();

                                    var itemIndex = record.data.findIndex(item => item.name === field.name);

                                    if (itemIndex < 0) {
                                        record.data.push({
                                            name: field.name
                                        });

                                        itemIndex = record.data.length - 1;
                                    }

                                    var fieldType = 'text';

                                    if (field.type === 'asset')
                                        fieldType = 'asset';

                                    record.data[itemIndex].type = fieldType;

                                    var fieldIdentifier = "data[" + itemIndex + "].value";

                                    if (field.type === 'text')
                                        return <TextInput key={field.name} label={field.name} source={fieldIdentifier}
                                                          defaultValue="" fullWidth={true}/>;
                                    else if (field.type === 'richtext')
                                        return <Field key={field.name} label={field.name} name={fieldIdentifier}
                                                      record={record}
                                                      component={renderTextField} source={fieldIdentifier}/>;
                                    else if (field.type === 'checkbox')
                                        return <BooleanInput key={field.name} label={field.name} source={fieldIdentifier}
                                                             defaultValue=""/>;
                                    else if (field.type === 'color')
                                        return <ColorInput key={field.name} label={field.name} source={fieldIdentifier}
                                                           defaultValue="#ffffff"/>;
                                    else if (field.type === 'select') {
                                        return <SelectInput key={field.name} label={field.name} source={fieldIdentifier}
                                                            choices={JSON.parse(field.options)} fullWidth={true}/>;
                                    } else if (field.type === 'asset')
                                        return <ReferenceInput key={field.name} label={field.name} source={fieldIdentifier}
                                                               resource="assets" reference="assets" allowEmpty
                                                               fullWidth={true}
                                                               filterToQuery={searchText => ({original_name: searchText})}>
                                            <AutocompleteInput optionText="original_name" fullWidth={true} allowEmpty/>
                                        </ReferenceInput>;

                                    return null;
                                }
                            )}
                        </span>
                    );
                } else
                    return null;
            }
            }
        </Query>
    ) : null
);

const renderTextField = ({input, label}) => {

    function handleEditorChange(e) {
        input.onChange(e.target.getContent());
    }

    var editorToolbar = 'menu_personalize';

    if (label === 'message') {
        editorToolbar += ' | ecard_button';
    } else if (label === 'email_body') {
        editorToolbar += ' | btn_ecard_link';
    } else if (label === 'email_header_message' || label === 'email_footer_message') {
        editorToolbar = 'link_url | email_url | unsubscribe';
    }

    return (
        <Labeled label={label}>
            <Editor
                apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
                label={label}
                key={input.name}
                initialValue={input.value}
                init={{
                    forced_root_block: false,
                    menubar: false,
                    plugins: 'link code autoresize',
                    toolbar1: 'undo redo | bold italic underline | alignleft aligncenter alignright | fontsizeselect | link unlink code | removeformat',
                    toolbar2: editorToolbar,
                    setup: (editor) => {
                        editor.ui.registry.addButton('btn_ecard_link', {
                            text: 'eCard Link',
                            onAction: () => editor.insertContent('[link]')
                        });

                        editor.ui.registry.addButton('link_url', {
                            text: 'eCard Link URL',
                            onAction: () => editor.insertContent('[link_url]')
                        });

                        editor.ui.registry.addButton('email_url', {
                            text: 'Email Url',
                            onAction: () => editor.insertContent('[email_url]')
                        });

                        editor.ui.registry.addButton('unsubscribe', {
                            text: 'Unsubscribe Link',
                            onAction: () => editor.insertContent('[unsubscribe]')
                        });

                        editor.ui.registry.addButton('ecard_button', {
                            text: 'Custom Button',
                            onAction: () => editor.insertContent('[button href="https://www.power-ecard.com" background="#6c0000" text="#ffffff" border="#ffffff" background-hover="#ffffff" text-hover="#6c0000" border-hover="#6c0000"]Label[/button]')
                        });

                        editor.ui.registry.addMenuButton('menu_personalize', {
                            text: 'Personalization',
                            fetch: function (callback) {
                                var items = [
                                    {
                                        type: 'menuitem',
                                        text: 'Salutation',
                                        onAction: () => editor.insertContent('[salutation]')
                                    },
                                    {
                                        type: 'nestedmenuitem',
                                        text: 'Receiver',
                                        getSubmenuItems: function () {
                                            return [
                                                {
                                                    type: 'menuitem',
                                                    text: 'Title',
                                                    onAction: () => editor.insertContent('[receiver_title]')
                                                },
                                                {
                                                    type: 'menuitem',
                                                    text: 'Firstname',
                                                    onAction: () => editor.insertContent('[receiver_firstname]')
                                                },
                                                {
                                                    type: 'menuitem',
                                                    text: 'Lastname',
                                                    onAction: () => editor.insertContent('[receiver_lastname]')
                                                }
                                            ];
                                        }
                                    },
                                    {
                                        type: 'nestedmenuitem',
                                        text: 'Sender',
                                        getSubmenuItems: function () {
                                            return [
                                                {
                                                    type: 'menuitem',
                                                    text: 'Name',
                                                    onAction: () => editor.insertContent('[sender_name]')
                                                },
                                                {
                                                    type: 'menuitem',
                                                    text: 'Email',
                                                    onAction: () => editor.insertContent('[sender_email]')
                                                }
                                            ];
                                        }
                                    }
                                ];
                                callback(items);
                            },
                        });
                    }
                }}
                onChange={handleEditorChange}
            />
        </Labeled>
    );
};

export const ThemeLocalizationCreate = props => (
    <Create {...props}>
        <TabbedForm>
            <FormTab label="General">
                <ReferenceInput source="theme_id" reference="themes"
                                filterToQuery={searchText => ({title: searchText})}>
                    <AutocompleteInput optionText="title" fullWidth={true}/>
                </ReferenceInput>
                <TextInput source="title"/>
                <TextInput source="description"/>
                <SelectInput source="language" choices={languageChoices}/>
            </FormTab>
            <FormTab label="Preview">
                <TextInput source="previewUrl"/>
                <ReferenceInput source="thumbnail_asset_id" reference="assets"
                                filterToQuery={searchText => ({original_name: searchText})}>
                    <AutocompleteInput optionText="original_name"/>
                </ReferenceInput>
                <ReferenceInput source="email_preview_asset_id" reference="assets"
                                filterToQuery={searchText => ({original_name: searchText})}>
                    <AutocompleteInput optionText="original_name"/>
                </ReferenceInput>
            </FormTab>
            <FormTab label="Data">
                <DataFields/>
            </FormTab>
        </TabbedForm>
    </Create>
);
