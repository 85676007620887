import React from 'react';
import {
    ArrayInput, AutocompleteInput,
    BooleanField,
    BooleanInput,
    CloneButton,
    Create,
    Datagrid,
    DeleteButton,
    Edit,
    EditButton,
    FormDataConsumer,
    FormTab,
    List,
    LongTextInput,
    NumberField,
    NumberInput,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    SimpleFormIterator,
    TabbedForm,
    TextField,
    TextInput
} from 'react-admin';

export const TemplateList = props => (
    <List {...props} exporter={false}>
        <Datagrid rowClick="edit">
            <TextField source="id"/>
            <TextField source="name"/>
            <TextField source="label.de" label="Label DE" sortable={false}/>
            <TextField source="label.en" label="Label EN" sortable={false}/>

            <ReferenceField source="template_pricing_id" reference="template-pricings">
                <TextField source="title"/>
            </ReferenceField>

            <ReferenceField source="default_theme_id" reference="themes" allowEmpty={true} filterToQuery={searchText => ({title: searchText})}>
                <TextField source="title"/>
            </ReferenceField>

            <NumberField source="version" sortable={false}/>
            <BooleanField source="published"/>
            <EditButton/>
            <CloneButton/>
            <DeleteButton/>
        </Datagrid>
    </List>
);

export const TemplateEdit = props => (
    <Edit {...props}>
        <TabbedForm>
            <FormTab label="Allgemein">
                <BooleanInput source="published"/>
                <TextInput source="name"/>
                <TextInput source="label.de" label="Label (DE)"/>
                <TextInput source="label.en" label="Label (EN)"/>
                <TextInput source="label.fr" label="Label (FR)"/>
                <TextInput source="label.nl" label="Label (NL)"/>
                <ReferenceInput source="template_pricing_id" reference="template-pricings">
                    <SelectInput optionText="title"/>
                </ReferenceInput>

                <ReferenceInput source="default_theme_id" reference="themes" allowEmpty={true} filterToQuery={searchText => ({title: searchText})}>
                    <AutocompleteInput optionText="title" />
                </ReferenceInput>

                <NumberInput source="version"/>
            </FormTab>
            <FormTab label="Template Daten">
                <TemplateDataFields/>
            </FormTab>
        </TabbedForm>
    </Edit>
);

export const TemplateCreate = props => (
    <Create {...props}>
        <TabbedForm>
            <FormTab label="Allgemein">
                <BooleanInput source="published"/>
                <TextInput source="name"/>
                <TextInput source="label.de" label="Label (DE)"/>
                <TextInput source="label.en" label="Label (EN)"/>
                <TextInput source="label.fr" label="Label (FR)"/>
                <TextInput source="label.nl" label="Label (NL)"/>

                <ReferenceInput source="template_pricing_id" reference="template-pricings">
                    <SelectInput optionText="title"/>
                </ReferenceInput>

                <ReferenceInput source="default_theme_id" reference="themes" allowEmpty={true} filterToQuery={searchText => ({title: searchText})}>
                    <AutocompleteInput optionText="title" />
                </ReferenceInput>

                <NumberInput source="version"/>
            </FormTab>
            <FormTab label="Template Daten">
                <TemplateDataFields/>
            </FormTab>
        </TabbedForm>
    </Create>
);

const dataTypes = [
    {id: 'text', name: 'Text'},
    {id: 'richtext', name: 'Rich Text'},
    {id: 'asset', name: 'Asset'},
    {id: 'checkbox', name: 'Checkbox'},
    {id: 'color', name: 'Color'},
    {id: 'select', name: 'Select'},
];

const TemplateDataFields = () => (
    <ArrayInput source="data">
        <SimpleFormIterator>
            <TextInput source="name"/>
            <SelectInput source="type" choices={dataTypes}/>
            <FormDataConsumer label="Options">
                {({formData, scopedFormData, getSource, record, ...rest}) => {
                    if (scopedFormData && 'type' in scopedFormData) {
                        if (scopedFormData.type === 'select') {
                            return <LongTextInput source={getSource('options')} {...rest} fullWidth={true}/>;
                        }
                    }
                    return null;
                }}
            </FormDataConsumer>
            {/*<FormDataConsumer label="Value">*/}
            {/*{({formData, scopedFormData, getSource, record, ...rest}) => {*/}
            {/*if (scopedFormData && 'type' in scopedFormData) {*/}
            {/*if (scopedFormData.type && scopedFormData.type === 'text')*/}
            {/*return <TextInput source={getSource('value')} {...rest} />;*/}
            {/*else*/}
            {/*return <NumberInput source={getSource('value')} {...rest} />;*/}
            {/*}*/}
            {/*return null;*/}
            {/*}}*/}
            {/*</FormDataConsumer>*/}
        </SimpleFormIterator>
    </ArrayInput>
);

// export default TemplateDataFields;
