// in src/App.js
import React from 'react';
import {Admin, Resource} from 'react-admin';
// import jsonServerProvider from 'ra-data-json-server';
import dataProvider from './dataProvider';
import authProvider from './authProvider';
import {ThemeList, ThemeEdit, ThemeCreate} from './resources/themes';
import {TemplateList, TemplateEdit, TemplateCreate} from './resources/templates';
import {ThemeLocalizationList, ThemeLocalizationEdit, ThemeLocalizationCreate} from './resources/themelocalizations';
import {AssetList, AssetEdit, AssetCreate} from './resources/assets';
import {PricingList, PricingCreate, PricingEdit} from './resources/pricings';
import {
    SimpleAttributeCreate,
    SimpleAttributeEdit,
    SimpleAttributeList,
    AttributeList,
    AttributeEdit,
    AttributeCreate
} from './resources/attribute';

const App = () => (
    <Admin dataProvider={dataProvider} authProvider={authProvider}>
        <Resource name="templates"
                  list={TemplateList}
                  edit={TemplateEdit}
                  create={TemplateCreate}
                  options={{label: 'Templates'}}/>
        <Resource name="template-pricings"
                  list={PricingList}
                  edit={PricingEdit}
                  create={PricingCreate}
                  options={{label: 'Template Pricings'}}/>
        <Resource name="themes"
                  list={ThemeList}
                  edit={ThemeEdit}
                  create={ThemeCreate}
                  options={{label: 'Themes'}}/>
        <Resource name="theme-pricings"
                  list={PricingList}
                  edit={PricingEdit}
                  create={PricingCreate}
                  options={{label: 'Theme Pricings'}}/>
        <Resource name="theme-localizations"
                  list={ThemeLocalizationList}
                  edit={ThemeLocalizationEdit}
                  create={ThemeLocalizationCreate}
                  options={{label: 'Theme Localizations'}}/>
        <Resource name="assets"
                  list={AssetList}
                  edit={AssetEdit}
                  create={AssetCreate}
                  options={{label: 'Assets'}}/>
        <Resource name="asset-types"
                  list={AttributeList}
                  edit={AttributeEdit}
                  create={AttributeCreate}
                  options={{label: 'Asset Types'}}/>
        <Resource name="asset-mimes"
                  list={SimpleAttributeList}
                  edit={SimpleAttributeEdit}
                  create={SimpleAttributeCreate}
                  options={{label: 'Asset Mimes'}}/>
        <Resource name="categories"
                  list={AttributeList}
                  edit={AttributeEdit}
                  create={AttributeCreate}/>
        <Resource name="styles"
                  list={AttributeList}
                  edit={AttributeEdit}
                  create={AttributeCreate}/>
        <Resource name="colors"
                  list={AttributeList}
                  edit={AttributeEdit}
                  create={AttributeCreate}/>
        <Resource name="tags"
                  list={AttributeList}
                  edit={AttributeEdit}
                  create={AttributeCreate}/>
    </Admin>
);

export default App;