import React from 'react';
import {
    Create,
    Datagrid,
    DeleteButton,
    Edit,
    EditButton,
    FileField,
    FileInput,
    Filter,
    FormTab,
    List,
    ReferenceArrayInput,
    ReferenceField,
    ReferenceInput,
    SelectArrayInput,
    SelectInput,
    SimpleForm,
    TabbedForm,
    TextField,
    TextInput
} from 'react-admin';
import Poster from './poster';


export const AssetListFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Dateiname" source="original_name" alwaysOn/>
    </Filter>
);

export const AssetList = props => (
    <List {...props} exporter={false} filters={<AssetListFilter/>}>
        <Datagrid rowClick="edit">
            <TextField source="id"/>
            <TextField source="title"/>
            <TextField source="original_name"/>
            <ReferenceField source="asset_mime_id" reference="asset-mimes">
                <TextField source="title"/>
            </ReferenceField>
            <ReferenceField source="asset_type_id" reference="asset-types">
                <TextField source="title"/>
            </ReferenceField>
            <EditButton/>
            <DeleteButton/>
        </Datagrid>
    </List>
);

export const AssetEdit = props => (
    <Edit {...props}>
        <TabbedForm>
            <FormTab label="General">
                <TextInput source="title"/>
                <TextInput source="description"/>
                <Poster/>
                <ReferenceInput source="asset_mime_id" reference="asset-mimes">
                    <SelectInput optionText="title"/>
                </ReferenceInput>
                <ReferenceInput source="asset_type_id" reference="asset-types">
                    <SelectInput optionText="title"/>
                </ReferenceInput>
            </FormTab>
            <FormTab label="Attributes">
                <ReferenceArrayInput source="color_ids" reference="colors" label="Colors">
                    <SelectArrayInput optionText="title"/>
                </ReferenceArrayInput>

                <ReferenceArrayInput source="style_ids" reference="styles" label="Styles">
                    <SelectArrayInput optionText="title"/>
                </ReferenceArrayInput>

                <ReferenceArrayInput source="tag_ids" reference="tags" label="Tags">
                    <SelectArrayInput optionText="title"/>
                </ReferenceArrayInput>

                <ReferenceArrayInput source="category_ids" reference="categories" label="Categories">
                    <SelectArrayInput optionText="title"/>
                </ReferenceArrayInput>
            </FormTab>
            <FormTab label="Upload">
                <FileInput source="file" label="Asset files" accept="image/*, video/*, audio/*">
                    <FileField source="src" title="title"/>
                </FileInput>
            </FormTab>
            <FormTab label="File Info">
                <TextField source="path"/>
                <TextField source="original_name"/>
            </FormTab>
        </TabbedForm>
    </Edit>
);

export const AssetCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="title"/>
            <TextInput source="description"/>
            <FileInput source="file" label="Asset files" accept="image/*, video/*, audio/*">
                <FileField source="src" title="title"/>
            </FileInput>
            <ReferenceInput source="asset_mime_id" reference="asset-mimes">
                <SelectInput optionText="title"/>
            </ReferenceInput>
            <ReferenceInput source="asset_type_id" reference="asset-types">
                <SelectInput optionText="title"/>
            </ReferenceInput>
        </SimpleForm>
    </Create>
);
